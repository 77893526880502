import '../styles/globals.css'
import type { AppProps } from 'next/app'
import { ReactElement, useEffect } from 'react';
import { CssBaseline, ThemeProvider, Theme, StyledEngineProvider } from '@mui/material';
import StylesProvider from '@mui/styles/StylesProvider';
import { theme } from '../src/theme';
import { NextPage } from 'next';

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}
type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactElement;
};
type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const  MyApp = ({ Component, pageProps }: AppPropsWithLayout)=>{
  const isWindow = typeof window !== 'undefined';
  useEffect(() => {
    let vh = 768;
    if (isWindow) {
      vh = window.innerHeight * 0.01;
    }
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles?.parentElement?.removeChild(jssStyles);
    }

    const appHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty('--app-height', `${window.innerHeight}px`);
    };
    window.addEventListener('resize', appHeight);
    appHeight();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLayout = Component.getLayout ?? ((page) => <>{page}</>);

  if (process.browser && isWindow) {
  
  return (
  <>
  <StylesProvider injectFirst={false}>
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                  <CssBaseline />
                  {getLayout(<Component {...pageProps} />)}
                  </ThemeProvider>
              </StyledEngineProvider>
            </StylesProvider>
  </>
  )
  }
  else {
    return (
    <>
      <StylesProvider injectFirst={false}>
                  <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={theme}>
                      <CssBaseline />
                      <Component {...pageProps} />
                      </ThemeProvider>
                  </StyledEngineProvider>
                </StylesProvider>
      </>
      )
  }
}

export default MyApp
